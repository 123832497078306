import { AppProps } from "next/app";
import Head from "next/head";
import posthog from "posthog-js";
import { PostHogProvider } from "posthog-js/react";
import { MantineProvider } from "@mantine/core";
import { Notifications } from "@mantine/notifications";
import { getUser } from "../lib/session";
import { User } from "../types";

if (typeof window !== "undefined") {
  // checks that we are client-side
  posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY, {
    api_host:
      process.env.NEXT_PUBLIC_POSTHOG_HOST || "https://eu.i.posthog.com",
    loaded: (posthog) => {
      if (process.env.NODE_ENV === "development") posthog.debug(); // debug mode in development
    },
  });
}

export default function App(props: AppProps & { user: User }) {
  const { Component, pageProps, user } = props;

  const newProps = { ...pageProps, user };
  return (
    <>
      <Head>
        <title>
          Moosiqs - Take control and share your music collection today
        </title>
        <meta
          name="viewport"
          content="minimum-scale=1, initial-scale=1, width=device-width"
        />
        <link rel="shortcut icon" href="/favicon.svg" />
      </Head>

      <MantineProvider withGlobalStyles withNormalizeCSS>
        <PostHogProvider client={posthog}>
          <Notifications />
          <Component {...newProps} />
        </PostHogProvider>
      </MantineProvider>
    </>
  );
}

App.getInitialProps = async (appContext) => {
  const { req, res } = appContext.ctx;
  return { user: (await getUser(req, res)) || null };
};
